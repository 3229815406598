.hero-area {
  background-image: url('../images/slider/slider-bg-1.jpg');
  background-size: cover;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content:'';
    background: rgba(0, 0, 0, 0.63);
    position: absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
  }
  .block {
    color: $light;
    h1 {
      font-size: 60px;
      font-weight: 700;
      margin-bottom: 20px;
      text-transform: uppercase;
    }
    p {
      color: $light;
      width: 50%;
      margin-bottom: 20px
    }
    .btn-main {
      margin-right:8px;
      &:hover {
        opacity: .8;
      }
    }
  }
}

.hero-area-video {
  height: 100vh;
  position: inherit!important;
  display: flex;
  justify-content: center;
  align-items: center;
  .block {
    color: $light;
    text-align: center;
    h1 {
      font-size: 60px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    p {
      color: $light;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 3px;
      margin-bottom: 20px
    }
    .btn-main {
      margin-top: 20px;
    }
  } 
}

.dark-bg {
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}